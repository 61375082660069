module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NG42DZZ","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{}},"routeChangeEventName":"route-change"},
    },{
      plugin: require('../node_modules/@avldev/gatsby-theme-core-ui/gatsby-browser.js'),
      options: {"plugins":[],"includeSnippetsInDevelopment":true,"callrail":{"key":"821350168","token":"4e519522a92bc9e1f968"},"googleReviews":{"createReviewNodes":true,"settings":{"key":"ChIJoS2U-j7zWYgROXyjlRkQR9s","minimumRating":4,"nodeType":"GoogleReview","numberOfReviews":48,"orderBy":"date","withTextOnly":true}}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
